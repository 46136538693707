<template>
  <div>
    <div>
      <table class="fullbet odds-details">
        <thead>
          <tr>
            <th
              class="border-solid border font-bold text-white align-middle leading-6 text-xs"
              rowspan="2"
            >
              {{ $t('time') }}
            </th>
            <th
              class="border-solid border font-bold text-white align-middle leading-6 text-xs"
              rowspan="2"
              colspan="2"
            >
              {{ $t('event') }}
            </th>
            <th
              class="border-solid border font-bold text-white align-middle leading-6 text-xs"
              colspan="6"
            >
              {{ $t('fullTime') }}
            </th>
            <th
              class="orange-bg border-solid border font-bold text-white align-middle leading-6 text-xs"
              colspan="6"
            >
              {{ $t('firstHalf') }}
            </th>
          </tr>
          <tr>
            <th
              class="border-solid border font-bold text-white align-middle leading-6 text-xs"
            >
              {{ $t('hdp') }}
            </th>
            <th
              class="border-solid border font-bold text-white align-middle leading-6 text-xs"
            >
              {{ $t('home') }}
            </th>
            <th
              class="border-solid border font-bold text-white align-middle leading-6 text-xs"
            >
              {{ $t('away') }}
            </th>
            <th
              class="border-solid border font-bold text-white align-middle leading-6 text-xs"
            >
              {{ $t('goal') }}
            </th>
            <th
              class="border-solid border font-bold text-white align-middle leading-6 text-xs"
            >
              {{ $t('over') }}
            </th>
            <th
              class="border-solid border font-bold text-white align-middle leading-6 text-xs"
            >
              {{ $t('under') }}
            </th>
            <th
              class="orange-bg border-solid border font-bold text-white align-middle leading-6 text-xs"
            >
              {{ $t('hdp') }}
            </th>
            <th
              class="orange-bg border-solid border font-bold text-white align-middle leading-6 text-xs"
            >
              {{ $t('home') }}
            </th>
            <th
              class="orange-bg border-solid border font-bold text-white align-middle leading-6 text-xs"
            >
              {{ $t('away') }}
            </th>
            <th
              class="orange-bg border-solid border font-bold text-white align-middle leading-6 text-xs"
            >
              {{ $t('goal') }}
            </th>
            <th
              class="orange-bg border-solid border font-bold text-white align-middle leading-6 text-xs"
            >
              {{ $t('over') }}
            </th>
            <th
              class="orange-bg border-solid border font-bold text-white align-middle leading-6 text-xs"
            >
              {{ $t('under') }}
            </th>
          </tr>
        </thead>
        <tbody v-for="(listItem, index) in filterList" :key="index">
          <tr v-show="isShowLeague(listItem?.league?.en)">
            <td
              colspan="5"
              class="league-name uppercase text-white text-left font-bold"
            >
              {{ listItem.league?.[locale] }}
            </td>
            <td
              colspan="10"
              class="league-name uppercase text-white text-left font-bold"
            />
          </tr>
          <tr
            v-for="(item, indexItem) in listItem?.record"
            v-show="
              isShowTeamByTime(item, false, bettingState.timeNow) &&
              isShowLeague(listItem?.league?.en)
            "
            :key="indexItem"
            class="odds-group"
          >
            <td class="!px-0.5">
              {{ timeFormat(item?.koTime) }}
            </td>
            <td>
              <span :class="advColorClass(ADV_SELECTION.HOME_ADV, item?.adv)">
                {{ item?.home?.[locale] }}
              </span>
            </td>
            <td>
              <span :class="advColorClass(ADV_SELECTION.AWAY_ADV, item?.adv)">
                {{ item?.away?.[locale] }}
              </span>
            </td>
            <td class="odds-line">
              <span v-html="hdpFormat(item?.hdp)" />
            </td>
            <td
              @click="
                onClickOdds(
                  {
                    id: item?.id,
                    matchId: item?.matchId,
                    betType: 'hdp',
                    oddsType: bettingOddsType.HDP_HOME,
                    value: item?.hdpHome,
                    oldValue: item?.hdpHome,
                    label: item?.home,
                    odds: item?.hdp,
                    adv: item?.adv,
                    isHome: true,
                    oddsLine: item?.hdpOddsLine,
                    league: listItem?.league,
                  },
                  item
                )
              "
            >
              <span>
                <a
                  class="odds cursor-pointer block border-none w-full m-0 p-0 whitespace-nowrap text-center leading-7 font-bold"
                >
                  {{ oddsFormat(item?.hdpHome) }}
                </a>
              </span>
            </td>
            <td
              @click="
                onClickOdds(
                  {
                    id: item?.id,
                    matchId: item?.matchId,
                    betType: 'hdp',
                    oddsType: bettingOddsType.HDP_AWAY,
                    value: item?.hdpAway,
                    oldValue: item?.hdpAway,
                    label: item?.away,
                    odds: item?.hdp,
                    adv: item?.adv,
                    isHome: false,
                    oddsLine: item?.hdpOddsLine,
                    league: listItem?.league,
                  },
                  item
                )
              "
            >
              <span>
                <a
                  class="odds cursor-pointer block border-none w-full m-0 p-0 whitespace-nowrap text-center leading-7 font-bold"
                >
                  {{ oddsFormat(item?.hdpAway) }}
                </a>
              </span>
            </td>
            <td class="odds-line">
              <span v-html="hdpFormat(item?.ou)" />
            </td>
            <td
              @click="
                onClickOdds(
                  {
                    id: item?.id,
                    matchId: item?.matchId,
                    betType: 'ou',
                    oddsType: bettingOddsType.OU_OVER,
                    value: item?.ouOver,
                    oldValue: item?.ouOver,
                    label: `${$t('over')}`,
                    odds: item?.ou,
                    adv: item?.adv,
                    oddsLine: item?.ouOddsLine,
                    league: listItem?.league,
                  },
                  item
                )
              "
            >
              <span>
                <a
                  class="odds cursor-pointer block border-none w-full m-0 p-0 whitespace-nowrap text-center leading-7 font-bold"
                >
                  {{ oddsFormat(item?.ouOver) }}
                </a>
              </span>
            </td>
            <td
              @click="
                onClickOdds(
                  {
                    id: item?.id,
                    matchId: item?.matchId,
                    betType: 'ou',
                    oddsType: bettingOddsType.OU_UNDER,
                    value: item?.ouUnder,
                    oldValue: item?.ouUnder,
                    label: `${$t('under')}`,
                    odds: item?.ou,
                    adv: item?.adv,
                    oddsLine: item?.ouOddsLine,
                    league: listItem?.league,
                  },
                  item
                )
              "
            >
              <span>
                <a
                  class="odds cursor-pointer block border-none w-full m-0 p-0 whitespace-nowrap text-center leading-7 font-bold"
                >
                  {{ oddsFormat(item?.ouUnder) }}
                </a>
              </span>
            </td>
            <td class="odds-line">
              <span v-html="hdpFormat(item?.fhHdp)" />
            </td>
            <td
              class="orange-bg"
              @click="
                onClickOdds(
                  {
                    id: item?.id,
                    matchId: item?.matchId,
                    betType: 'hdp',
                    oddsType: bettingOddsType.FH_HDP_HOME,
                    value: item?.fhHdpHome,
                    oldValue: item?.fhHdpHome,
                    label: `(1st) ${item?.home[locale]}`,
                    odds: item?.fhHdp,
                    adv: item?.adv,
                    isHome: true,
                    oddsLine: item?.fhHdpOddsLine,
                    league: listItem?.league,
                  },
                  item
                )
              "
            >
              <span>
                <a
                  class="odds cursor-pointer block border-none w-full m-0 p-0 whitespace-nowrap text-center leading-7 font-bold"
                >
                  {{ oddsFormat(item?.fhHdpHome) }}
                </a>
              </span>
            </td>
            <td
              class="orange-bg"
              @click="
                onClickOdds(
                  {
                    id: item?.id,
                    matchId: item?.matchId,
                    betType: 'hdp',
                    oddsType: bettingOddsType.FH_HDP_AWAY,
                    value: item?.fhHdpAway,
                    oldValue: item?.fhHdpAway,
                    label: `(1st) ${item?.away[locale]}`,
                    odds: item?.fhHdp,
                    adv: item?.adv,
                    isHome: false,
                    oddsLine: item?.fhHdpOddsLine,
                    league: listItem?.league,
                  },
                  item
                )
              "
            >
              <span>
                <a
                  class="odds cursor-pointer block border-none w-full m-0 p-0 whitespace-nowrap text-center leading-7 font-bold"
                >
                  {{ oddsFormat(item?.fhHdpAway) }}
                </a>
              </span>
            </td>
            <td class="odds-line">
              <span v-html="hdpFormat(item?.fhOu)" />
            </td>
            <td
              class="orange-bg"
              @click="
                onClickOdds(
                  {
                    id: item?.id,
                    matchId: item?.matchId,
                    betType: 'ou',
                    oddsType: bettingOddsType.FH_OU_OVER,
                    value: item?.fhOuOver,
                    oldValue: item?.fhOuOver,
                    label: `(1st) ${$t('over')}`,
                    odds: item?.fhOu,
                    adv: item?.adv,
                    oddsLine: item?.fhOuOddsLine,
                    league: listItem?.league,
                  },
                  item
                )
              "
            >
              <span>
                <a
                  class="odds cursor-pointer block border-none w-full m-0 p-0 whitespace-nowrap text-center leading-7 font-bold"
                >
                  {{ oddsFormat(item?.fhOuOver) }}
                </a>
              </span>
            </td>
            <td
              class="orange-bg"
              @click="
                onClickOdds(
                  {
                    id: item?.id,
                    matchId: item?.matchId,
                    betType: 'ou',
                    oddsType: bettingOddsType.FH_OU_UNDER,
                    value: item?.fhOuUnder,
                    oldValue: item?.fhOuUnder,
                    label: `(1st) ${$t('under')}`,
                    odds: item?.fhOu,
                    adv: item?.adv,
                    oddsLine: item?.fhOuOddsLine,
                    league: listItem?.league,
                  },
                  item
                )
              "
            >
              <span>
                <a
                  class="odds cursor-pointer block border-none w-full m-0 p-0 whitespace-nowrap text-center leading-7 font-bold"
                >
                  {{ oddsFormat(item?.fhOuUnder) }}
                </a>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from 'vue';
import { useHdpFormat } from '@/composition/hdp-format';
import { BettingTableStateKey } from '@/constants/BettingTableState.enum';
import { useI18n } from 'vue-i18n';
import { useUnixTimeToDate } from '@/composition/datetime-format';
import { useAdvTextColor } from '@/composition/adv-color';
import { ADV_SELECTION } from '@/constants/Ticket.enum';
import { useOddsFormat } from '@/composition/odds-format';
import { useBettingStore } from '../../store/betting';
import { BettingOddsType } from '@/constants/OddsType.enum';
import { useLeagueDisplay } from '../../composition/league-display';

import type { FullBet } from '@/interfaces/FullBet.interface';
import type { FbMatch } from '@/interfaces/FbMatch.interface';
import type { TeamSelected } from '../../interfaces/TeamSelected.interface';
import { useMemberStore } from '../../store/member';

export default defineComponent({
  name: 'FullBetTable',
  props: {
    list: {
      type: Array as PropType<FullBet[]>,
      required: true,
    },
  },
  emits: ['click:match', 'click:odds'],
  setup(props, { emit }) {
    const { locale } = useI18n();
    const bettingStore = useBettingStore();
    const memberStore = useMemberStore();
    const bettingOddsType = computed(() => BettingOddsType);
    const { isShowLeague, isShowTeamByTime } = useLeagueDisplay();

    const currentTable = computed(
      () => bettingStore.$state.currentBettingTable
    );
    const member = computed(() => memberStore.member);
    const filterList = computed(() => filterMuay(props.list));

    const bettingState = computed(() => bettingStore.$state);
    const bettingTableStateKey = computed(() => BettingTableStateKey);

    const hdpFormat = (hdp: string | number) => useHdpFormat(hdp);

    const oddsFormat = (oddsValue: number) => {
      return useOddsFormat(oddsValue);
    };
    const timeFormat = (accDate: string) => {
      return useUnixTimeToDate(accDate, 'HH:mm');
    };

    const advColorClass = (currentAdv: number, advValue: number) => {
      return useAdvTextColor(currentAdv, advValue);
    };

    const validateUserPermission = () => {
      return member.value?.isFt;
    };

    const filterMuay = (list: FullBet[]): FullBet[] => {
      return list.filter((el) => {
        const enUppercase = el.league.en?.toUpperCase();
        const thUppercase = el.league.th;
        const isMuayEn = enUppercase?.includes('MUAY');
        const isMuayTh = thUppercase?.includes('มวย');
        return !isMuayEn && !isMuayTh;
      });
    };

    const onClickOdds = (teamSelected: TeamSelected, matchItem: FbMatch) => {
      if (validateUserPermission()) {
        if (teamSelected.value >= 0) {
          emit('click:odds', teamSelected);
          emit('click:match', matchItem);
        }
      } else {
        bettingStore.bettingErrorMessage =
          'ยูซเซอร์ไม่สามารถแทงประเภท เต็มเวลา/ครึ่งแรก ได้';
        bettingStore.isShowBettingModal = true;
      }
    };

    return {
      bettingOddsType,
      currentTable,
      bettingTableStateKey,
      hdpFormat,
      onClickOdds,
      locale,
      ADV_SELECTION,
      timeFormat,
      advColorClass,
      oddsFormat,
      isShowLeague,
      isShowTeamByTime,
      filterList,
      bettingState,
    };
  },
});
</script>

<style>
table.fullbet.odds-details tr:nth-child(1) th:nth-child(1),
table.fullbet.odds-details tr:nth-child(2) th {
  width: auto;
  padding: 0 6px;
}
</style>
