<template>
  <div>
    <div id="ticket-form-list" class="overflow-y-auto max-h-[35vh]">
      <ticket-form-list
        :bet-list="betList"
        :selected-team-list="selectedTeamList"
        @click:delete="onDeleteTicket"
      />
    </div>
    <div class="tickets-info pt-2 bg-blue-200">
      <form name="bet-form" @submit.prevent="onSubmitTicket">
        <div class="stake flex justify-center">
          <input
            v-model="betAmount"
            :class="
              isSubmit && !Number(betAmount) && 'border-red-500 bg-red-50'
            "
            type="number"
            :placeholder="$t('stake')"
            class="text-center font-bold text-sm leading-8 rounded border"
          />
        </div>
        <div
          v-if="bettingErrorMessage"
          class="mt-1 p-1 bg-red-100 text-red-600 text-xs flex items-center justify-center font-semibold gap-1"
        >
          <font-awesome-icon
            icon="fa-solid fa-triangle-exclamation"
            class="text-xs font-bold"
          />
          {{ bettingErrorMessage }}
        </div>
        <div class="stake-info py-1">
          <table class="w-full text-xs">
            <tbody>
              <tr class="parlay">
                <td class="w-3/6">
                  <span>{{ $t('ticket.step') }} =</span>
                </td>
                <td class="font-semibold w-3/6">
                  <span class="text-right">{{ step }}</span>
                </td>
              </tr>
              <tr />
              <tr class="mix-parlay-info bold">
                <td class="w-3/6">
                  <span>{{ $t('ticket.mixParlay') }} @</span>
                </td>
                <td class="font-semibold w-3/6">
                  <number-format
                    v-slot="{ number }"
                    :is-decimal="true"
                    :decimal="2"
                    :number="Number(sumParlay)"
                    :class="{
                      'bg-odds-changed px-0.5': errorInvalidOdds.length > 0,
                    }"
                  >
                    {{ number }}
                  </number-format>
                </td>
              </tr>
              <tr class="min-max">
                <td class="w-3/6">
                  <span>{{ $t('ticket.minBet') }} =</span>
                </td>
                <td class="font-semibold w-3/6">
                  <span>{{ minBet }}</span>
                </td>
              </tr>
              <tr>
                <td class="w-3/6">
                  <span>{{ $t('ticket.maxBet') }} =</span>
                </td>
                <td class="font-semibold w-3/6">
                  {{ maxBet }}
                </td>
              </tr>
              <tr>
                <td class="w-3/6">
                  <span>{{ $t('ticket.maxPayout') }} =</span>
                </td>
                <td class="font-semibold w-3/6">
                  <number-format
                    v-slot="{ number }"
                    :is-decimal="true"
                    :decimal="2"
                    :number="maxPayout"
                  >
                    {{ number }}
                  </number-format>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <span class="ng-binding" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="bet-buttons text-center pt-1 pb-3">
          <input
            type="button"
            :value="$t('cancel')"
            class="confirm-bet-btn rounded text-xs border-0 font-bold leading-6 my-0 px-4"
            @click="onCancelTicket"
          />
          <button
            type="submit"
            class="cancel-bet-btn disabled:opacity-40 disabled:pointer-events-none rounded text-xs border-0 font-bold leading-6 my-0 px-4 ml-1.5 w-20"
            :disabled="
              loadingStore.is('createTicket') ||
              (errorTicketList.length > 0 &&
                errorInvalidOdds.length !== errorTicketList.length)
            "
          >
            <font-awesome-icon
              v-if="loadingStore.is('createTicket')"
              icon="fa-solid fa-spinner"
              class="animate-spin"
            />
            <span v-else>{{ $t('submit') }}</span>
          </button>
        </div>
      </form>
    </div>
    <ConfirmModal
      :title="$t('ticketConfirm.title')"
      :is-open="isShowModal"
      :loading="loadingStore.is('createTicket')"
      @close-notify-modal="onCloseModal"
      @submit-notify-modal="onSubmitModal"
    >
      <div class="grid gap-2">
        <div>
          {{ $t('ticketConfirm.message') }}
        </div>
      </div>
    </ConfirmModal>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import NumberFormat from '@/components/NumberFormat.vue';
import { useMemberStore } from '../store/member';
import { useNumberUtils } from '../composition/number-utils';
import { useLoadingStore } from '../store/wait';
import TicketFormList from '@/components/TicketFormList.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
import { useBetTicketStore } from '../store/betTicket';

import dayjs from 'dayjs';

import type { TeamSelected } from '@/interfaces/TeamSelected.interface';
import type { CreateTicketInput } from '@/interfaces/api/CreateTicketInput.interface';
import type { MatchSelected } from '../interfaces/Ticket.interface';
import type { ComputedRef, PropType, Ref } from 'vue';
import { useBettingStore } from '@/store/betting';

export default defineComponent({
  name: 'TicketForm',
  components: {
    ConfirmModal,
    NumberFormat,
    TicketFormList,
  },
  props: {
    betList: {
      type: Array as PropType<MatchSelected[]>,
      default: () => [],
    },
    selectedTeamList: {
      type: Array as PropType<TeamSelected[]>,
      default: () => [],
    },
  },
  emits: ['click:delete', 'click:cancel', 'submit-ticket'],
  setup(props, { emit }) {
    const betTicketStore = useBetTicketStore();
    const memberStore = useMemberStore();
    const { formatNumber } = useNumberUtils();

    const isSubmit = ref(false);
    const isShowModal = ref(false);
    const bettingErrorMessage = ref<string | null>(null);
    const loadingStore = useLoadingStore();

    const memberState = computed(() => memberStore.$state);
    const errorTicketList = computed(() =>
      betTicketStore.ticketList.filter((el) => !!el.error)
    );

    const errorInvalidOdds = computed(() =>
      errorTicketList.value.filter(
        (el) => el.error?.errorCode === 'InvalidOdds'
      )
    );

    const betAmount: Ref<number | null> = ref(null);
    const minBet: ComputedRef<number> = computed(() =>
      Number(memberState.value.member?.betMin)
    );
    const maxBet: ComputedRef<string> = computed(() =>
      formatNumber(Number(memberState.value.member?.betMax))
    );

    const maxPayout: Ref<number> = ref(0);

    const selectedTeamList: ComputedRef<TeamSelected[]> = computed(
      () => betTicketStore.$state.selectedTeamList
    );

    const sumParlay: ComputedRef<number> = computed(() =>
      selectedTeamList.value?.reduce(
        (accumulator: number, current: TeamSelected) => {
          return accumulator * Number(current.value);
        },
        1
      )
    );
    const step: ComputedRef<number> = computed(() => props.betList?.length);

    const setMaxPayout = (betAmount: number) => {
      maxPayout.value = betAmount * sumParlay.value;
    };

    watch(betAmount, (newBetAmount) => {
      setMaxPayout(newBetAmount);
    });

    watch(
      selectedTeamList,
      () => {
        setMaxPayout(betAmount.value);
      },
      { immediate: true }
    );

    const onDeleteTicket = (matchId: number) => {
      emit('click:delete', matchId);
    };

    const onCancelTicket = () => {
      emit('click:cancel');
    };

    const filterPayload = () => {
      return props.selectedTeamList?.map((item: TeamSelected) => {
        return {
          matchRateId: item?.id,
          odds: Number(item?.value),
          type: item?.oddsType,
          oddsLine: Number(item?.oddsLine),
        };
      });
    };

    const isValidateMinAndMax = computed(() => {
      // Validate min and max for betting max parlay
      const maxParlay = 15; // mix parlay limit 15
      const isMixParlay = selectedTeamList.value?.length >= 2; // check mix parlay
      const isMinParlay =
        selectedTeamList.value?.length >=
        Number(memberState.value.member?.parlayMin);
      const isMaxParlay =
        selectedTeamList.value?.length <=
        Number(memberState.value.member?.parlayMax);
      if (
        isMixParlay &&
        isMinParlay &&
        isMaxParlay &&
        selectedTeamList.value?.length <= maxParlay
      ) {
        return true;
      }
      return false;
    });

    const isValidateMaxPayout = computed(() => {
      return maxPayout.value <= Number(memberState.value.member?.maxPayout); // validate max payout
    });

    // const isValidateMuay = computed(() => {
    //   // validate close muay
    //   const isClose = dayjs().isAfter(
    //     dayjs(`${dayjs().format('MM/DD/YYYY')} 19:00:00`)
    //   );
    //   return (
    //     !isClose &&
    //     selectedTeamList.value.some((el) => {
    //       const enUppercase = el.league?.en.toUpperCase();
    //       const thUppercase = el.league?.th;
    //       const isMuayEn = enUppercase?.includes('MUAY');
    //       const isMuayTh = thUppercase?.includes('มวย');
    //       if (isMuayEn || isMuayTh) {
    //         return !isClose;
    //       }
    //     })
    //   );
    // });

    const userValidateError = computed(() => {
      if (memberState.value.member?.isSuspensed) {
        return 'ลูกค้าถูกระงับการใช้งาน';
      }
      if (memberState.value.member?.isLocked) {
        return 'ลูกค้าถูกล็อคการใช้งาน';
      }
      if (memberState.value.member?.isClosed) {
        return 'ลูกค้าถูกปิดการใช้งาน';
      }
      return null;
    });

    const balanceValidateError = computed(() => {
      if (
        memberState.value.member?.balance
          ? memberState.value.member?.balance < Number(betAmount.value)
          : false
      ) {
        return 'จำนวนเครดิตไม่เพียงพอ';
      }
      return null;
    });

    const betLimitValidateError = computed(() => {
      if (
        memberState.value.member?.betMax
          ? memberState.value.member?.betMax < Number(betAmount.value)
          : false
      ) {
        return `แทงได้สูงสุดไม่เกิน ${memberState.value.member?.betMax}`;
      }
      if (
        memberState.value.member?.betMin
          ? memberState.value.member?.betMin > Number(betAmount.value)
          : false
      ) {
        return `แทงได้ต่ำสุดไม่น้อยกว่า ${memberState.value.member?.betMin}`;
      }
      return null;
    });

    const singleValidate = computed(() => {
      const validMaxBet =
        Number(betAmount.value) <= Number(memberState.value.member?.maxSingle); // validate max bet
      const betSingleAllowed = memberState.value.member?.isSingle;
      if (!validMaxBet) {
        const errorMessage = `แทงสูงสุดไม่เกิน ${formatNumber(
          Number(memberState.value.member?.maxSingle)
        )} บาท`;
        return errorMessage;
      }
      if (!betSingleAllowed) {
        return 'ยูซเซอร์ไม่สามารถแทงประเภทเต็งได้';
      }
      return null;
    });

    const setErrorMessage = (errorMessage: string) => {
      bettingErrorMessage.value = errorMessage;
    };

    const onCloseModal = () => {
      isShowModal.value = false;
    };

    const onSubmitModal = () => {
      const payload: CreateTicketInput = {
        stake: Number(betAmount.value),
        parlay: filterPayload(),
      };
      isShowModal.value = false;
      const element = document.getElementById('ticket-form-list');
      element.scrollTop = 0;
      emit('submit-ticket', payload);
    };

    const onSubmitTicket = () => {
      isSubmit.value = true;
      if (Number(betAmount.value)) {
        if (userValidateError.value) {
          setErrorMessage(userValidateError.value);
        } else if (balanceValidateError.value) {
          setErrorMessage(balanceValidateError.value);
        } else if (betLimitValidateError.value) {
          setErrorMessage(betLimitValidateError.value);
        } else {
          const isSingle = selectedTeamList.value?.length === 1;
          if (isSingle) {
            console.log('5');
            if (singleValidate.value) {
              setErrorMessage(singleValidate.value);
            } else {
              isShowModal.value = true;
              bettingErrorMessage.value = null;
            }
          } else {
            if (!isValidateMaxPayout.value) {
              const errorMessage = `จ่ายสูงสุด ${formatNumber(
                Number(memberState.value.member?.maxPayout)
              )} บาท ต่อบิล`;
              setErrorMessage(errorMessage);
            } else if (!isValidateMinAndMax.value) {
              const errorMessage = `จำนวนคู่ต่ำ-สูงสุด ${parlayMin.value} - ${parlayMax.value}คู่ ต่อบิล`;
              setErrorMessage(errorMessage);
            } else {
              isShowModal.value = true;
              bettingErrorMessage.value = null;
            }
          }
        }
      }
    };

    const parlayMin = computed(() => {
      if (memberState.value.member?.parlayMin) {
        return formatNumber(memberState.value.member?.parlayMin, '0,0');
      }
      return '0';
    });

    const parlayMax = computed(() => {
      if (memberState.value.member?.parlayMax) {
        return formatNumber(memberState.value.member?.parlayMax, '0,0');
      }
      return '0';
    });

    return {
      onSubmitTicket,
      onCancelTicket,
      onDeleteTicket,
      betAmount,
      minBet,
      step,
      maxBet,
      maxPayout,
      sumParlay,
      isSubmit,
      loadingStore,
      errorTicketList,
      errorInvalidOdds,
      isShowModal,
      onCloseModal,
      onSubmitModal,
      bettingErrorMessage,
    };
  },
});
</script>

<style scoped lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.bg-odds-changed {
  @apply bg-yellow-200;
}
</style>

<style lang="scss">
.tickets-info {
  .bet-buttons {
    .cancel-bet-btn {
      box-shadow: 1px 1px 1px 1px #aaa;
      background-color: #fc0;
      &:focus {
        outline: 0;
      }
      &:active {
        box-shadow: inset 1px 1px 1px 1px #666;
      }
      &:hover {
        background-color: #ff9300;
      }
      &:disabled {
        background-color: #fc0;
      }
    }
    .confirm-bet-btn {
      box-shadow: 1px 1px 1px 1px #aaa;
      background-color: #ddd;
      &:focus {
        outline: 0;
      }
      &:active {
        box-shadow: inset 1px 1px 1px 1px #666;
      }
      &:hover {
        background-color: #b4b4b4;
      }
      &:disabled {
        background-color: rgba(255, 150, 0, 0.5);
      }
    }
  }
  .stake {
    input {
      width: 150px;
      box-shadow: inset 0 0 3px 1px #bbb;
    }
  }
  .stake-info {
    table {
      tr {
        td {
          &:nth-child(1) {
            text-align: right;
            line-height: 1.3em;
          }
          &:nth-child(2) {
            padding-left: 10px;
            text-align: left;
          }
        }
      }
    }
  }
}
input {
  box-shadow: inset 1px 0 1px 1px #666;
}
</style>
