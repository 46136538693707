<template>
  <div>
    <table class="odds-details">
      <thead>
        <tr>
          <th
            class="border-solid border font-bold text-white align-middle leading-6 text-xs"
            rowspan="2"
          >
            {{ $t('time') }}
          </th>
          <th
            class="border-solid border font-bold text-white align-middle leading-6 text-xs"
            colspan="2"
            rowspan="2"
          >
            {{ $t('event') }}
          </th>
          <th
            class="border-solid border font-bold text-white align-middle leading-6 text-xs"
            colspan="6"
          >
            {{ $t(bettingTableStateKey[currentTable]) }}
          </th>
        </tr>
        <tr>
          <th
            class="border-solid border font-bold text-white align-middle leading-6 text-xs"
          >
            {{ $t('hdp') }}
          </th>
          <th
            class="border-solid border font-bold text-white align-middle leading-6 text-xs"
          >
            {{ $t('home') }}
          </th>
          <th
            class="border-solid border font-bold text-white align-middle leading-6 text-xs"
          >
            {{ $t('away') }}
          </th>
          <th
            class="border-solid border font-bold text-white align-middle leading-6 text-xs"
          >
            {{ $t('goal') }}
          </th>
          <th
            class="border-solid border font-bold text-white align-middle leading-6 text-xs"
          >
            {{ $t('over') }}
          </th>
          <th
            class="border-solid border font-bold text-white align-middle leading-6 text-xs"
          >
            {{ $t('under') }}
          </th>
        </tr>
      </thead>
      <tbody v-for="(listItem, index) in list" :key="index">
        <tr
          v-show="
            isShowLeague(listItem?.league?.en) &&
            showMuayBetting(listItem?.league)
          "
        >
          <td
            :class="highlightMuayClass(listItem?.league)"
            colspan="4"
            class="league-name uppercase text-white text-left font-bold"
          >
            {{ listItem?.league?.[locale] }}
          </td>
          <td
            :class="highlightMuayClass(listItem?.league)"
            colspan="5"
            class="league-name uppercase text-white text-left font-bold"
          />
        </tr>
        <tr
          v-for="(item, indexItem) in filterMatchList(listItem)"
          v-show="
            isShowTeamByTime(item, false, bettingState.timeNow) &&
            isShowLeague(listItem?.league?.en) &&
            showMuayBetting(listItem?.league)
          "
          :key="indexItem"
          class="odds-group"
        >
          <td>
            {{ timeFormat(item?.koTime) }}
          </td>
          <td>
            <span
              :class="advColorClass(ADV_SELECTION.HOME_ADV, item?.adv)"
              v-html="item?.home?.[locale]"
            />
          </td>
          <td>
            <span
              :class="advColorClass(ADV_SELECTION.AWAY_ADV, item?.adv)"
              v-html="item?.away?.[locale]"
            />
          </td>
          <td class="odds-line">
            <span v-html="hdpFormat(item?.hdp)" />
          </td>
          <td
            @click="
              onClickTeam(
                {
                  id: item?.id,
                  matchId: item?.matchId,
                  betType: 'hdp',
                  oddsType: bettingOddsType.HDP_HOME,
                  value: item?.hdpHome,
                  oldValue: item?.hdpHome,
                  label: item?.home,
                  odds: item?.hdp,
                  adv: item?.adv,
                  isHome: true,
                  oddsLine: item.hdpOddsLine,
                  league: listItem?.league,
                },
                item
              )
            "
          >
            <span>
              <a
                class="odds cursor-pointer block border-none w-full m-0 p-0 whitespace-nowrap text-center leading-7 font-bold"
              >
                {{ oddsFormat(item?.hdpHome) }}
              </a>
            </span>
          </td>
          <td
            @click="
              onClickTeam(
                {
                  id: item?.id,
                  matchId: item?.matchId,
                  betType: 'hdp',
                  oddsType: bettingOddsType.HDP_AWAY,
                  value: item?.hdpAway,
                  oldValue: item?.hdpAway,
                  label: item?.away,
                  odds: item?.hdp,
                  adv: item?.adv,
                  isHome: false,
                  oddsLine: item.hdpOddsLine,
                  league: listItem?.league,
                },
                item
              )
            "
          >
            <span>
              <a
                class="odds cursor-pointer block border-none w-full m-0 p-0 whitespace-nowrap text-center leading-7 font-bold"
              >
                {{ oddsFormat(item?.hdpAway) }}
              </a>
            </span>
          </td>
          <td class="odds-line">
            <span v-html="hdpFormat(item?.ou)" />
          </td>
          <td
            @click="
              onClickTeam(
                {
                  id: item?.id,
                  matchId: item?.matchId,
                  betType: 'ou',
                  oddsType: bettingOddsType.OU_OVER,
                  value: item?.ouOver,
                  oldValue: item?.ouOver,
                  label: `${$t('over')}`,
                  odds: item?.ou,
                  adv: item?.adv,
                  oddsLine: item.ouOddsLine,
                  league: listItem?.league,
                },
                item
              )
            "
          >
            <span>
              <a
                class="odds cursor-pointer block border-none w-full m-0 p-0 whitespace-nowrap text-center leading-7 font-bold"
              >
                {{ oddsFormat(item?.ouOver) }}
              </a>
            </span>
          </td>
          <td
            @click="
              onClickTeam(
                {
                  id: item?.id,
                  matchId: item?.matchId,
                  betType: 'ou',
                  oddsType: bettingOddsType.OU_UNDER,
                  value: item?.ouUnder,
                  oldValue: item?.ouUnder,
                  label: `${$t('under')}`,
                  odds: item?.ou,
                  adv: item?.adv,
                  oddsLine: item.ouOddsLine,
                  league: listItem?.league,
                },
                item
              )
            "
          >
            <span>
              <a
                class="odds cursor-pointer block border-none w-full m-0 p-0 whitespace-nowrap text-center leading-7 font-bold"
              >
                {{ oddsFormat(item?.ouUnder) }}
              </a>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import type { Hdp } from '@/interfaces/Hdp.interface';
import type { HdpMatch } from '@/interfaces/HdpMatch.interface';
import type { TeamSelected } from '../../interfaces/TeamSelected.interface';

import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { useOddsFormat } from '@/composition/odds-format';
import { useUnixTimeToDate } from '@/composition/datetime-format';
import { useLeagueDisplay } from '@/composition/league-display';
import { useAdvTextColor } from '@/composition/adv-color';
import { useHdpFormat } from '@/composition/hdp-format';
import { ADV_SELECTION } from '@/constants/Ticket.enum';
import { BettingTableStateKey } from '@/constants/BettingTableState.enum';
import { useBettingStore } from '../../store/betting';
import { BettingOddsType } from '@/constants/OddsType.enum';
import type { League } from '../../interfaces/League.interface';
import dayjs from 'dayjs';
import { useMemberStore } from '@/store/member';

export default defineComponent({
  name: 'BettingTable',
  props: {
    list: {
      type: Array as PropType<Hdp[]>,
      default: () => [],
      required: true,
    },
  },
  emits: ['click:match', 'click:odds'],
  setup(_, { emit }) {
    const { locale } = useI18n();
    const bettingStore = useBettingStore();
    const memberStore = useMemberStore();
    const { isShowLeague, isShowTeamByTime } = useLeagueDisplay();

    const bettingState = computed(() => bettingStore.$state);

    const bettingOddsType = computed(() => BettingOddsType);

    const currentTable = computed(
      () => bettingStore.$state.currentBettingTable
    );
    const bettingTableStateKey = computed(() => BettingTableStateKey);

    const hdpFormat = (hdp: string | number) => useHdpFormat(hdp);

    const timeFormat = (accDate: string) => {
      return useUnixTimeToDate(accDate, 'HH:mm');
    };

    const oddsFormat = (oddsValue: number) => {
      return useOddsFormat(oddsValue);
    };

    const advColorClass = (currentAdv: number, advValue: number) => {
      return useAdvTextColor(currentAdv, advValue);
    };

    const onClickTeam = (teamSelected: TeamSelected, matchItem: HdpMatch) => {
      if (teamSelected?.value >= 0) {
        emit('click:odds', teamSelected);
        emit('click:match', matchItem);
      }
    };

    const showMuayBetting = (league: League) => {
      const isCloseBettingTime = dayjs().isBefore(
        dayjs(`${dayjs().format('MM/DD/YYYY')} 19:00:00`)
      );
      const enUppercase = league.en?.toUpperCase();
      const thUppercase = league.th;
      const isMuayEn = enUppercase?.includes('MUAY');
      const isMuayTh = thUppercase?.includes('มวย');
      if (isMuayEn || isMuayTh) {
        return memberStore.member?.isMt && isCloseBettingTime;
      }
      return true;
    };

    const highlightMuayClass = (league: League) => {
      const enUppercase = league.en?.toUpperCase();
      const thUppercase = league.th;
      const isMuayEn = enUppercase?.includes('MUAY');
      const isMuayTh = thUppercase?.includes('มวย');
      if (isMuayEn || isMuayTh) {
        return 'league-name-muay';
      }
      return '';
    };

    const filterMatchList = (listItem: Hdp) => {
      const enUppercase = listItem.league.en?.toUpperCase();
      const thUppercase = listItem.league.th;
      if (enUppercase?.includes('MUAY') || thUppercase?.includes('มวย')) {
        return listItem.record.filter((el) => {
          return dayjs(el.koTime).diff(bettingState.value.timeNow, 'hour') > 0;
        });
      }
      return listItem.record;
    };

    return {
      bettingOddsType,
      currentTable,
      bettingTableStateKey,
      onClickTeam,
      locale,
      timeFormat,
      oddsFormat,
      advColorClass,
      ADV_SELECTION,
      hdpFormat,
      bettingState,
      isShowLeague,
      isShowTeamByTime,
      highlightMuayClass,
      filterMatchList,
      showMuayBetting,
    };
  },
});
</script>

<style lang="scss">
@media print {
  body {
    padding: 0 20px;
  }
  div.c-column {
    font-size: 11px;
    margin-left: 0;
    padding-left: 0;
  }
  .l-column,
  .headerboxblue {
    display: none;
  }
  table.odds-details th {
    border-color: #000000 !important;
  }
  div.blue-bar {
    display: none;
  }
  .header-inner {
    position: absolute;
    top: 0;
    width: 100%;
    height: 30px;
    border: none;

    #top-banner-time {
      display: block;
      position: absolute;
      font-size: 2em;
      right: 0;
    }
    .product-list {
      display: none !important;
    }
    .announcement {
      display: none !important;
    }
    .lang-selection {
      display: none !important;
    }
    .wrap-date {
      flex-direction: column;
      align-items: flex-end;
    }
    .max-payout {
      font-size: 18px !important;
      margin-top: 4px !important;
    }
  }
  .logo-print {
    display: block;
    position: absolute;
    left: 0;
    font-weight: bold;
    font-size: 2em;
  }
  .l-column {
    display: none;
  }
  .headerboxblue {
    display: none;
  }
  .blue-bar {
    display: none;
  }
  .c-column {
    margin: 0 auto;
    float: left;
    width: 100%;
    position: absolute;
    top: 90px;
    padding: 0;
  }
  table.odds-details {
    width: 95%;
    &.odds-live {
      display: none;
    }
    border-collapse: separate;
    border-spacing: 0;
    tr,
    td,
    th {
      color: #000000;
      border: 1px solid #333;
    }
    td:nth-child(n + 3) {
      width: 8%;
    }
  }
}
</style>
